<template>
    <div class="RPd">
        <div class="r-box" style="margin-bottom: 30px;">
            <div class="r-cell">
                <div class="entName">{{comParam.entName}}</div>
                <div class="search">
                    <div class="in-item">
                        <div class="title">招聘会：</div>
                        <el-input class="input-v" @focus="handleShowBooth" v-model="boothTitle" placeholder="请选择招聘会"></el-input>
                    </div>
                    <div class="in-item">
                        <div class="title">联系人：</div>
                        <el-input class="input-v" v-model="YDJson.name" placeholder="请输入联系人"></el-input>
                    </div>
                    <div class="in-item">
                        <div class="title">联系电话：</div>
                        <el-input class="input-v" v-model="YDJson.phone" placeholder="请输入联系电话"></el-input>
                    </div>
                </div>
                <div class="ok-change" v-if="boothList.length > 0">
                    <span>已选择展位：</span>
                    <span>{{changeValue}}</span>
                    <!--                        ，再次点击展位可以取消-->
                    <span>(最多只能选择 <span>1</span> 个展位)</span>
                </div>
            </div>
            <div class="r-cell" style="overflow-x: auto" v-if="boothList.length > 0">
                <div class="booth-box-view" :style="{width:winWidth}">
                    <!--临时-->
                    <div class="box-view view-02 linshi">
                        <div class="cell-item" @click="handleChange(m,n,0)" @mouseleave="handleMouseout(0)" @mouseenter="handleMouseover(m,n,0)"
                             :class="{stop:m.status == 3,okChange:m.status == 2,show:show0 == n,uChange:m.isChecked}"
                             v-for="(m,n) in boothList.slice(53,68).reverse()" :key="m.id">
                            <!--{{m.bno.replace('A','')}}-->
                            <span style="transform: scale(0.8)">临</span>{{(n + 1)}}
                        </div>
                    </div>
                    <!--临时end-->
                    <div class="box-view view-01">
                        <div class="cell-item" @click="handleChange(m,n,0)" @mouseleave="handleMouseout(0)" @mouseenter="handleMouseover(m,n,0)" :class="{stop:m.status == 3,okChange:m.status == 2,show:show0 == n,uChange:m.isChecked}"
                             v-for="(m,n) in dataArr0" :key="m.id">
                            <div class="cb-box">{{m.bno.replace('A','')}}</div>
                            <div class="show-hover" @mouseout="viewMouseOut">
                                <div class="hover-box">
                                    <div class="h-head">
                                        <div class="name" :title="m.entname">{{m.entname}}</div>
                                        <div class="text">已预定</div>
                                    </div>
                                    <div class="h-body">
                                        <div class="job-list" v-for="(v,l) in JobInfoData.jobinfo" :key="l">
                                            <div class="job-name" :title="v.jobname">{{v.jobname}}</div>
                                            <div class="price">{{$setSalary(v.minSalary,v.maxSalary).value}}</div>
                                        </div>
                                    </div>
                                    <div class="h-footer">
                                        <button @click="handleOpenView(m)">查看详情</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cell-item" @click="handleChange(m,n,1)" @mouseleave="handleMouseout(1)" @mouseenter="handleMouseover(m,n,1)" :class="{stop:m.status == 3,okChange:m.status == 2,show:show1 == n,uChange:m.isChecked}"
                             v-for="(m,n) in dataArr1" :key="m.id">
                            <div class="cb-box">{{m.bno.replace('A','')}}</div>
                            <div class="show-hover" @mouseout="viewMouseOut">
                                <div class="hover-box">
                                    <div class="h-head">
                                        <div class="name" :title="m.entname">{{m.entname}}</div>
                                        <div class="text">已预定</div>
                                    </div>
                                    <div class="h-body">
                                        <div class="job-list" v-for="(v,l) in JobInfoData.jobinfo" :key="l">
                                            <div class="job-name" :title="v.jobname">{{v.jobname}}</div>
                                            <div class="price">{{$setSalary(v.minSalary,v.maxSalary).value}}</div>
                                        </div>
                                    </div>
                                    <div class="h-footer">
                                        <button @click="handleOpenView(m)">查看详情</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-view view-02">
                        <div class="cell-item" @click="handleChange(m,n,2)" @mouseleave="handleMouseout(2)" @mouseenter="handleMouseover(m,n,2)" :class="{stop:m.status == 3,okChange:m.status == 2,show:show2 == n,uChange:m.isChecked}"
                             v-for="(m,n) in dataArr2" :key="m.id">
                            <div class="cb-box">{{m.bno.replace('A','')}}</div>
                            <div class="show-hover" @mouseout="viewMouseOut">
                                <div class="hover-box">
                                    <div class="h-head">
                                        <div class="name" :title="m.entname">{{m.entname}}</div>
                                        <div class="text">已预定</div>
                                    </div>
                                    <div class="h-body">
                                        <div class="job-list" v-for="(v,l) in JobInfoData.jobinfo" :key="l">
                                            <div class="job-name" :title="v.jobname">{{v.jobname}}</div>
                                            <div class="price">{{$setSalary(v.minSalary,v.maxSalary).value}}</div>
                                        </div>
                                    </div>
                                    <div class="h-footer">
                                        <button @click="handleOpenView(m)">查看详情</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cell-item" @click="handleChange(m,n,3)" @mouseleave="handleMouseout(3)" @mouseenter="handleMouseover(m,n,3)" :class="{stop:m.status == 3,okChange:m.status == 2,show:show3 == n,uChange:m.isChecked}"
                             v-for="(m,n) in dataArr3" :key="m.id">
                            <div class="cb-box">{{m.bno.replace('A','')}}</div>
                            <div class="show-hover" @mouseout="viewMouseOut">
                                <div class="hover-box">
                                    <div class="h-head">
                                        <div class="name" :title="m.entname">{{m.entname}}</div>
                                        <div class="text">已预定</div>
                                    </div>
                                    <div class="h-body">
                                        <div class="job-list" v-for="(v,l) in JobInfoData.jobinfo" :key="l">
                                            <div class="job-name" :title="v.jobname">{{v.jobname}}</div>
                                            <div class="price">{{$setSalary(v.minSalary,v.maxSalary).value}}</div>
                                        </div>
                                    </div>
                                    <div class="h-footer">
                                        <button @click="handleOpenView(m)">查看详情</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-view view-03">
                        <div class="cell-item" @click="handleChange(m,n,4)" @mouseleave="handleMouseout(4)" @mouseenter="handleMouseover(m,n,4)" :class="{stop:m.status == 3,okChange:m.status == 2,show:show4 == n,uChange:m.isChecked}"
                             v-for="(m,n) in dataArr4" :key="m.id">
                            <div class="cb-box">{{m.bno.replace('A','')}}</div>
                            <div class="show-hover" @mouseout="viewMouseOut">
                                <div class="hover-box">
                                    <div class="h-head">
                                        <div class="name" :title="m.entname">{{m.entname}}</div>
                                        <div class="text">已预定</div>
                                    </div>
                                    <div class="h-body">
                                        <div class="job-list" v-for="(v,l) in JobInfoData.jobinfo" :key="l">
                                            <div class="job-name" :title="v.jobname">{{v.jobname}}</div>
                                            <div class="price">{{$setSalary(v.minSalary,v.maxSalary).value}}</div>
                                        </div>
                                    </div>
                                    <div class="h-footer">
                                        <button @click="handleOpenView(m)">查看详情</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-view view-04 u-hide">
                        <div class="cell-item" @click="handleChange(m,n,5)" @mouseleave="handleMouseout(5)" @mouseenter="handleMouseover(m,n,5)" :class="{stop:m.status == 3,okChange:m.status == 2,show:show5 == n,uChange:m.isChecked}"
                             v-for="(m,n) in dataArr5" :key="m.id">
                            <div class="cb-box">{{m.bno.replace('A','')}}</div>
                            <div class="show-hover" @mouseout="viewMouseOut">
                                <div class="hover-box">
                                    <div class="h-head">
                                        <div class="name" :title="m.entname">{{m.entname}}</div>
                                        <div class="text">已预定</div>
                                    </div>
                                    <div class="h-body">
                                        <div class="job-list" v-for="(v,l) in JobInfoData.jobinfo" :key="l">
                                            <div class="job-name" :title="v.jobname">{{v.jobname}}</div>
                                            <div class="price">{{$setSalary(v.minSalary,v.maxSalary).value}}</div>
                                        </div>
                                    </div>
                                    <div class="h-footer">
                                        <button @click="handleOpenView(m)">查看详情</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-view view-05 u-hide">
                        <div class="cell-item" @click="handleChange(m,n,6)" @mouseleave="handleMouseout(6)" @mouseenter="handleMouseover(m,n,6)" :class="{stop:m.status == 3,okChange:m.status == 2,show:show6 == n,uChange:m.isChecked}"
                             v-for="(m,n) in dataArr6" :key="m.id">
                            <div class="cb-box">{{m.bno.replace('A','')}}</div>
                            <div class="show-hover" @mouseout="viewMouseOut">
                                <div class="hover-box">
                                    <div class="h-head">
                                        <div class="name" :title="m.entname">{{m.entname}}</div>
                                        <div class="text">已预定</div>
                                    </div>
                                    <div class="h-body">
                                        <div class="job-list" v-for="(v,l) in JobInfoData.jobinfo" :key="l">
                                            <div class="job-name" :title="v.jobname">{{v.jobname}}</div>
                                            <div class="price">{{$setSalary(v.minSalary,v.maxSalary).value}}</div>
                                        </div>
                                    </div>
                                    <div class="h-footer">
                                        <button @click="handleOpenView(m)">查看详情</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="booth-list-box box-view-booth" :style="{width:winWidth}" style="padding-top: 0;">
                    <div class="view-box u-flex"><span></span><span>可预定</span></div>
                    <div class="view-box u-flex"><span></span><span>已预定</span></div>
                    <div class="view-box u-flex"><span></span><span>暂停预定</span></div>
                </div>
            </div>
            <div class="r-cell job-box">
                <div class="job-heard">
                    <div class="j-c">请选择您本次要招聘的岗位：</div>
                    <div class="j-c">
                        <el-checkbox :value="isChecked" @change="changeAll" :label="label" name="type"></el-checkbox>
                    </div>
                </div>
                <div class="job-cell">
                    <div class="job-list" @click="handleChangeZw(m,n)" :class="{active:m.isChange}" v-for="(m,n) in jobData" :key="n">
                        <div class="job-r">
                            <div class="job-name" :title="m.jobname">{{m.jobname}}</div>
                            <div class="job-c">
                                <span :title="m.wcountyName">{{m.wcountyName}}</span>
                                <span>
                                    {{
                                    (m.workExperienceMin === -1 && m.workExperienceMax === -1) || (m.workExperienceMin === 0 && m.workExperienceMax === 0)
                                    ? "不限"
                                    : m.workExperienceMin == -1
                                    ? m.workExperienceMax + "年以下"
                                    : m.workExperienceMax == -1
                                    ? m.workExperienceMin + "年以上"
                                    : m.workExperienceMin +"-"+ m.workExperienceMax + "年"
                                    }}
                                </span>
                                <span>{{$setEdu(m.educationRequire)}}</span>
                            </div>
                        </div>
                        <div class="job-l">{{$setSalary(m.minSalary,m.maxSalary).value}}</div>
                        <div class="changeBox"><i class="icon">&#xe601;</i></div>
                    </div>
                </div>
                <div class="page-box" >
                    <el-pagination :hide-on-single-page="true" @current-change="onJobPage($event)"  background layout="prev, pager, next"
                    :current-page="pageIndex" :page-size="jobPager.pageSize" :total="jobPager.recordCount"></el-pagination>
                </div>
            </div>

            <el-button class="sendBtn-comfir" @click="handlSubmit()" type="primary">提交</el-button>
        </div>


        <el-dialog class="showBooth" title="选择招聘会" :visible.sync="showBooth" width="60%">
            <div class="show-booth">
                <div class="b-h">
                    <el-date-picker @change="changeTime($event)" v-model="dateTime" type="daterange" align="right"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" unlink-panels range-separator="至"
                    start-placeholder="开始举办时间日期" end-placeholder="结束举办时间日期"></el-date-picker>
                    <div class="s-box">
                        <el-input @input="handlSearch" v-model="searchParam.keyWords" style="width: 65%;" prefix-icon="el-icon-search" placeholder="请输入内容"></el-input>
                        <el-button @click="handlSearch" type="primary" icon="el-icon-search">搜索</el-button>
                    </div>
                </div>
<!--                <div class="ck" style="color: #cc8239;">注：点击行即可选择</div>-->
                <div class="b-b" style="min-height: 400px;">
                    <el-table title="点击当前行即可选择" highlight-current-row max-height="450" :data="tableData"  @current-change="handleCurrentChange($event)"
                            style="width: 100%;margin-bottom: 20px" border>
                        <el-table-column type="index" width="50" align="center"></el-table-column>
                        <el-table-column prop="title" label="招聘会标题" width="350" align="center"></el-table-column>
                        <el-table-column prop="holdtime" label="举办日期" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.holdtime}} 至 {{scope.row.holdendtime}}
                            </template>
                        </el-table-column>
                        <el-table-column label="预定状态" align="center" width="200">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status==1">开放预订</span>
                                <span v-if="scope.row.status==2">停止预订</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createtime" label="新增日期" align="center" width="211"></el-table-column>
                    </el-table>
                    <div v-if="pager.pageCount > 1" style="height:60px;text-align: center;display: flex;align-items: center;justify-content: center">
                        <el-pagination :hide-on-single-page="true" @current-change="currentChange($event)"  background layout="prev, pager, next"
                        :current-page="searchParam.page" :page-size="pager.pageSize" :total="pager.recordCount"></el-pagination>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="onCanlen">取 消</el-button>
                <el-button type="primary" @click="onConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Util from "../../plugins/util";

export default {
    name:'RecruitPreordain',
    data(){
        return{
            label:"全选",
            isChecked:false,
            boothTitle:"",
            showBooth:true,
            winHeight:"",
            dateTime:"",
            winWidth:"1440px",
            selectRmareaIndex: 0,
            tabRmlistArry:[],
            rmData: {}, //招聘会信息
            tabRmBootchlistArry:[], //展区的展位
            entjobinfo:[],
            comParam:{
                entid:""
            },
            searchParam:{
                status:1,
                holdtime:'',
                holdendtime:'',
                keyWords:'',
                isexp:1,
                page:1,
                limit:10
            },
            changeValue:"",
            tableData:[],
            boothList:[],
            dataArr0:[],
            dataArr1:[],
            dataArr2:[],
            dataArr3:[],
            dataArr4:[],
            dataArr5:[],
            dataArr6:[],
            show0:-1,
            show1:-1,
            show2:-1,
            show3:-1,
            show4:-1,
            show5:-1,
            show6:-1,
            pager:{},
            jobPager:{},
            boothJson:{},
            pageIndex:0,
            boothID:"",//a77911d3f9ad401d8c760da7f8308918
            JobInfoData:{},
            YDJson:{
                ids:"",
                name:"",
                phone:"",
                jobids:"",
                boothNumber:"",
                zhiweiList:[]
            },
            jobData:[],
            pageIndex:1,
            limit:20,
        }
    },
   /* props:{
         comRecruit:Object
    },*/
    created(){
        var _self=this;
        //接收地址栏参数
        this.comParam = this.$router.currentRoute.query;
        this.YDJson.name = this.comParam.contactsPerson;
        this.YDJson.phone = this.comParam.contactsNumber;

        this.getJobList();
        this.getRecruitList();
        console.log(this.comParam)
    },
    mounted() {
        this.getBootchList();
        let he = document.documentElement.clientHeight || document.body.clientHeight;
        this.winHeight = (he - 140) + "px";
        console.log('wi------------------------')
    },
    methods:{
        showAlert(msg){
            this.$alert(msg, '温馨提示')
        },
        handlSubmit(){
            if(this.boothTitle == ""){
                this.showAlert('请选择招聘会')
            }else if(this.YDJson.name === ""){
                this.showAlert('请输入联系人名称')
            }else if(this.YDJson.phone === ""){
                this.showAlert('请输入联系电话')
            }else if(!this.Util.phoneVail(this.YDJson.phone)){
                this.showAlert('请输入正确的联系电话')
            }else if(this.YDJson.boothNumber === ""){
                this.showAlert('请选择要预定的展位')
            }else if(this.YDJson.zhiweiList.length === 0){
                this.$confirm("请选择您本次要招聘的职位,或新增职位","温馨提示",{
                    confirmButtonText: '新增',
                    cancelButtonText: '取消'
                }).then( () => {
                    this.$parent.pullItem("添加职位", "JobAdd",{entid:this.comParam.entid,entname:this.comParam.entName});
                })
            }else{

                let isId = this.YDJson.zhiweiList;
                isId.forEach(item => {
                    this.YDJson.jobids += item+ "-"
                })
                // eslint-disable-next-line no-console
                console.log(this.YDJson)
                this.boothReservation()
            }
        },
        boothReservation(){
            this.axios({
                url:this.API.job_fairs.boothReservation,
                method:"post",
                params:{
                    ids:this.YDJson.ids,
                    entid:this.comParam.entid,
                    entname:this.comParam.entName,
                    contactsPerson:this.YDJson.name,
                    contactsNumber:this.YDJson.phone,
                    jobids:this.YDJson.jobids,
                },
                headers:{
                    token:this.Util.getjwtToken(),
                }
            }).then( v =>{
                let d = v.data
                console.log( d );
                if(d.code == 200 && d.success){
                    this.$confirm(d.msg, '提示', {
                        confirmButtonText: '确定',
                        showCancelButton:false,
                        type: 'success'
                    }).then(() => {
                        this.getBootchList();
                        this.clearData();
                        // this.openView('zhaoPingGuanLi','');
                    }).catch(() => {

                    });
                }else{
                    this.showAlert(d.msg)
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        clearData(){
            this.YDJson.zhiweiList = [];
            this.YDJson.jobids = "";
            this.boothTitle = this.YDJson.name = this.YDJson.phone = this.YDJson.ids = "";
        },
        onJobPage(v){
            this.pageIndex = v;
            this.getJobList();
        },
        handleChangeZw(m,n){
            if(this.jobData[n].isChange){
                this.jobData[n].isChange = false;
                this.isChecked = false;
                this.label = '全选';
                console.log(this.jobData[n].id)
                this.YDJson.zhiweiList.forEach((item,idx)=>{
                    if(this.jobData[n].id === item){
                        this.YDJson.zhiweiList.splice(idx,1)
                        console.log(this.YDJson.zhiweiList)
                    }
                })
            }else{
                this.jobData[n].isChange = true;
                this.YDJson.zhiweiList.push(this.jobData[n].id)
                console.log(this.YDJson.zhiweiList)
                if(this.YDJson.zhiweiList.length === this.jobData.length){
                    this.isChecked = true;
                    this.label = '取消全选';
                }
            }
        },
        changeAll(){
            if(this.isChecked){
                this.isChecked = false;
                this.label = '全选';
                this.jobData.forEach((it)=>{it.isChange = false})
                this.YDJson.zhiweiList = [];

            }else {
                this.YDJson.zhiweiList = [];
                this.isChecked = true;
                this.label = '取消全选';
                this.jobData.forEach((item)=>{
                    item.isChange = true
                    this.YDJson.zhiweiList.push(item.id);
                })
            }
            console.log(this.YDJson.zhiweiList)
            this.$forceUpdate()
        },
        getJobList(){
            axios.get(this.API.ajob.list, {
                headers: {
                    token: this.Util.getjwtToken()
                },
                params:{
                    entname:this.comParam.entName,
                    jobtype:-1,
                    recruitType:-1,
                    page:this.pageIndex,
                    limit:this.limit
                }
            }).then( d => {
                let v = d.data;
                console.log("职位列表------------------------")
                console.log(v)
                if(v.code == 200 && v.success){
                    let jobData = v.data.list;
                    jobData.forEach(item => {
                        item.isChange = false;
                    })

                    this.jobData = jobData;
                    this.jobPager = v.data.pager;
                }
            })
        },
        handleOpenView(m){
            console.log(m)
            this.$parent.pullItem("企业详情","EnterpriseDetail",{
                id:this.JobInfoData.enterprise.id,
                entName:this.JobInfoData.enterprise.name,
                contactsPerson:this.JobInfoData.enterprise.contactsPerson,
                contactsNumber:this.JobInfoData.enterprise.contactsNumber
            })
            // this.$open_data('companyDetails',m.entid)
        },
        handleChange(item,n,k){
            console.log(item)
            console.log(k)
            if(item.status == 3){
                this.$alert('当前 '+ item.bno +' 展位不可预定', '温馨提示', {
                    confirmButtonText: '确定',
                });
            }else if(item.status == 2){
                // this.$alert('当前 '+ item.bno +' 展位已被预定', '温馨提示', {
                //     confirmButtonText: '确定',
                // });

                this.$parent.pullItem("企业详情","EnterpriseDetail",{
                    id:this.JobInfoData.enterprise.id,
                    entName:this.JobInfoData.enterprise.name,
                    contactsPerson:this.JobInfoData.enterprise.contactsPerson,
                    contactsNumber:this.JobInfoData.enterprise.contactsNumber
                })
            }else {
                if(item.isChange){
                    // this.changeValue = ""
                    // this.YDJson.ids = "";
                }else {
                    this.changeValue = item.bno;
                    this.YDJson.boothNumber = item.bno;
                    //获取所选的展位id
                    this.YDJson.ids = item.id;

                    this.boothList.forEach((n)=>{
                        if(n.id == item.id){
                            n.isChecked = true;
                        }else{
                            // n.isChange = false;
                        }
                    })
                    this.$forceUpdate();
                }

                this.setChecked(item)
            }
        },
        setChecked(d){
            axios({
                url: this.API.job_fairs.getRecruitMeetingReserve,
                method:"get",
                params:{
                    id:this.boothID,
                    entid:this.comParam.entid,
                },
                headers:{
                    token:this.Util.getjwtToken(),
                }
            }).then((response)=>{
                let res = response.data;
                if(res.code == 200 && res.success){
                    let boothList = res.data.list[0].rm_bootch_list
                    boothList.forEach(item=>{
                        if(d.id == item.id){
                            item.isChecked = true;
                        }else{
                            item.isChecked = false;
                        }
                        item.index = item.bno.slice(1);
                    })
                    this.boothList = boothList.sort(this.sortArr('index'));

                    let Arr0 = this.boothList;
                    let Arr1 = this.boothList;
                    let Arr2 = this.boothList;
                    let Arr3 = this.boothList;
                    let Arr4 = this.boothList;
                    let Arr5 = this.boothList;
                    let Arr6 = this.boothList;

                    console.log(this.boothList)
                    this.dataArr0 = Arr0.slice(11,22).reverse();
                    this.dataArr1 = Arr1.slice(0,11);
                    this.dataArr2 = Arr2.slice(34,46).reverse();
                    this.dataArr3 = Arr3.slice(22,34);
                    this.dataArr4 = Arr4.slice(46,52).reverse();
                    this.dataArr5 = Arr5.slice(52,54);
                    this.dataArr6 = Arr6.slice(54,75).reverse();
                    this.$forceUpdate()

                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        viewMouseOut(){
            this.isHover = true;
        },
        handleMouseover(m,n,j){
            this.thisID = m.id;

            if(m.status == 2){
                if(j == 0){
                    this.show0 = n;
                }else if(j == 1){
                    this.show1 = n;
                }else if(j == 2){
                    this.show2 = n;
                }else if(j == 3){
                    this.show3 = n;
                }else if(j == 4){
                    this.show4 = n;
                }else if(j == 5){
                    this.show5 = n;
                }else if(j == 6){
                    this.show6 = n;
                }
                console.log(m)
                this.getJobInfo(m.id)

                // let leng = jobList.length;

            }
        },
        handleMouseout(n){
            if(n == 0){
                this.show0 = -1;
            }else if(n == 1){
                this.show1 = -1;
                // this.show1 = 1;
            }else if(n == 2){
                this.show2 = -1;
            }else if(n == 3){
                this.show3 = -1;
            }else if(n == 4){
                this.show4 = -1;
            }else if(n == 5){
                this.show5 = -1;
            }else if(n == 6){
                this.show6 = -1;
            }
        },
        getJobInfo(boothID){
            this.axios({
                url:this.API.job_fairs.getBoothJob,
                method:"get",
                params:{
                    id:boothID,
                },
                headers:{
                    token:this.Util.getjwtToken(),
                }
            }).then((response)=>{
                let res = response.data;
                console.log('---------职位123列表--------')
                console.log(res)
                if(res.code == 200 && res.success){
                    this.JobInfoData = res.data;
                    this.$forceUpdate();
                }
            }).catch((err)=>{
                this.log(err)
            })
        },
        onCanlen(){
            this.showBooth = false;
        },
        onConfirm(){
            if(this.boothID == ""){
                this.$alert("请选择招聘会")
            } else {
                this.boothTitle = this.boothJson.title;
                this.getBootchList()
                this.onCanlen();
            }
        },
        handleCurrentChange(v){
            this.boothID = v.id;
            this.boothJson = v;
            console.log(v)
        },
        handlSearch(){
            this.getRecruitList();
        },
        handleShowBooth(){
            this.showBooth = true;
            this.getRecruitList();
        },
        currentChange(n){
            this.YDJson.zhiweiList = [];
            this.isChecked = false;
            this.label = '全选';
            this.searchParam.page = n;
            this.getRecruitList();
        },
        changeTime(time){
            console.log(time)
            if(time && time.length>0){
                this.searchParam.holdtime = time[0];
                this.searchParam.holdendtime = time[1];
            }else{
                this.searchParam.holdtime='';
                this.searchParam.holdendtime='';
            }
            this.searchParam.page = 1;
            this.getRecruitList();

            // if( time === null ){
            //     this.MList.holdtime = this.MList.holdendtime = "";
            // }else {
            //     this.MList.holdtime = this.dTime.getTime(time[0]).Year
            //     this.MList.holdendtime = this.dTime.getTime(time[1]).Year
            // }
            // this.getMeetingList();
        },
        getRecruitList(){
            axios({
                url: this.API.job_fairs.getRecruitMeetingList,
                method: 'get',
                headers:{
                    token:this.Util.getjwtToken(),
                },
                params:this.searchParam
            }).then((e)=>{
                var resp = e.data;
                console.log(resp)
                if(resp.success){
                    this.tableData = resp.data.list;
                    this.pager = resp.data.pager;
                }
            }).catch(function(error){

            });
        },
        getBootchList(){//获取招聘会展区分布
            console.log(this.boothID)
            axios({
                url: this.API.job_fairs.getRecruitMeetingReserve,
                method:"get",
                params:{
                    id:this.boothID,
                    entid:this.comParam.entid,
                },
                headers:{
                    token:this.Util.getjwtToken(),
                }
            }).then((response)=>{
                let res = response.data;
                console.log('---------------展位情况--------------')
                console.log(res)
                if(res.code == 200 && res.success){
                    // let boothList = res.data
                    let boothList = res.data.list[0].rm_bootch_list
                    boothList.forEach(item=>{
                        item.isChange = false;
                        item.index = item.bno.slice(1);
                    })
                    this.boothList = boothList.sort(this.sortArr('index'));

                    let Arr0 = this.boothList;
                    let Arr1 = this.boothList;
                    let Arr2 = this.boothList;
                    let Arr3 = this.boothList;
                    let Arr4 = this.boothList;
                    let Arr5 = this.boothList;
                    let Arr6 = this.boothList;


                    this.dataArr0 = Arr0.slice(11,22).reverse();
                    this.dataArr1 = Arr1.slice(0,11);
                    this.dataArr2 = Arr2.slice(34,46).reverse();
                    this.dataArr3 = Arr3.slice(22,34);
                    this.dataArr4 = Arr4.slice(46,52).reverse();
                    this.dataArr5 = Arr5.slice(52,54);
                    this.dataArr6 = Arr6.slice(54,75).reverse();

                    this.$forceUpdate()
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        sortArr(property){
            // 数组按指定参数升序
            return function(a,b){
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        },
    }

}
</script>
<style lang="scss" scoped>
    @font-face {
        font-family: 'iconfont';  /* project id 1565953 */
        src: url('https://at.alicdn.com/t/font_1565953_rpw8dl9qoo.eot');
        src: url('https://at.alicdn.com/t/font_1565953_rpw8dl9qoo.eot?#iefix') format('embedded-opentype'),
        url('https://at.alicdn.com/t/font_1565953_rpw8dl9qoo.woff2') format('woff2'),
        url('https://at.alicdn.com/t/font_1565953_rpw8dl9qoo.woff') format('woff'),
        url('https://at.alicdn.com/t/font_1565953_rpw8dl9qoo.ttf') format('truetype'),
        url('https://at.alicdn.com/t/font_1565953_rpw8dl9qoo.svg#iconfont') format('svg');
    }
    .icon{
        font-family: iconfont !important;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
    }
    .r-cell.job-box{
        max-width: 1440px;
    }
    .sendBtn-comfir {
        margin: 30px 0 20px;
        width: 10%;
    }
    .page-box{
        display: flex;
        align-items: center;
        max-height: 40px;
        margin-top: 30px;
        justify-content: center;
    }
.RPd{
    padding: 0 15px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    .r-box{
        box-sizing: border-box;
        padding: 15px 30px;
        background: #fff;
        .r-cell{
            .entName{
                max-width: 1440px;
                margin-bottom: 20px;
                border-bottom: 2px solid #ccc;
                box-sizing: border-box;
                padding-bottom: 15px;
                font-size: 16px;
                font-weight: bold;
                color: #333;
                text-align: center;
            }
            &:first-child{
                font-size: 15px;
                margin-bottom: 25px;
                padding-bottom: 15px;
                box-sizing: border-box;
                border-bottom: 1px solid #f1f1f1;
            }
            .search{
                display: flex;
                .in-item{
                    display: flex;
                    flex: 1;
                    align-items: center;
                    .title{
                        width: 15%;
                    }
                    .input-v{
                        width: 50%;
                    }
                }
            }
            .ok-change{
                margin-top: 20px;
                display: flex;
                align-items: center;

                span{
                    &:nth-child(2) {
                        width: 7%;
                        border: 1px solid #5b86ff;
                        text-align: center;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 4px;
                        color: #5b86ff;
                        font-weight: 700;
                        margin: 0 20px;
                    }

                    &:nth-child(3) {
                        color: #939393;
                    }

                    >span {
                        color: #5b86ff;
                        font-weight: 700;
                    }
                }
            }

            &.job-box{
                font-size: 15px;

                .job-heard{
                    display: flex;
                    align-items: center;
                    .j-c:last-child{
                        margin-left: 20px;
                    }
                }

                .job-cell{
                    display: flex;
                    flex-wrap: wrap;

                    .job-list{
                        display: flex;
                        width: 24%;
                        margin-right: 1.333%;
                        height: 90px;
                        border: 1px solid #dfdfdf;
                        border-radius: 4px;
                        align-items: center;
                        justify-content: space-between;
                        padding: 15px;
                        box-sizing: border-box;
                        box-shadow: 0 0 10px 0 #ece7f1;
                        margin-top: 20px;
                        position: relative;

                        &:nth-child(4),
                        &:nth-child(8),
                        &:nth-child(12){
                            margin-right: 0;
                        }

                        &:hover {
                            background: #f6f7fb;
                            cursor: pointer;
                        }

                        .job-r{
                            width: 75%;
                            .job-name{
                                margin-bottom: 10px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .job-c{
                                display: flex;
                                align-items: center;
                                span{
                                    font-size: 14px;
                                    color: #888686;
                                    margin-right: 20px;
                                    display: block;
                                    position: relative;
                                    &:after{
                                        position: absolute;
                                        content: "";
                                        width: 1px;
                                        height: 15px;
                                        right: -10px;
                                        border-right: 1px solid #ccc;
                                        top: 4px;
                                    }

                                    &:first-child{
                                        max-width: 30%;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                    }

                                    &:last-child:after{
                                        border-right: 0;
                                    }
                                }
                            }
                        }

                        .job-l{
                            font-size: 18px;
                            color: #ffaa42;
                        }

                        .changeBox{
                            position: absolute;
                            bottom: -3px;
                            right: 0;
                            display: none;

                            .icon{
                                font-size: 35px;
                                color: #507eff;
                            }
                        }

                        &.active{
                            .changeBox{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
.booth-list-box{
    width: 100%;
    display: flex;
    margin: 30px 0 20px;
    justify-content: center;
    .view-box{
        display: flex;
        height: 35px;
        align-items: center;
        margin-right: 50px;
        span{
            &:first-child{
                color: #979797;
                border: 1px solid #507EFF;
                width: 80px;
                height: 35px;
                border-radius: 4px;
                background-color: #507EFF;
                margin-right: 15px;
                display: block;
            }
        }
        &:first-child{
            span:first-child{
                background: #9addf7;
                border: 1px solid #9addf7;
            }
        }

        &:nth-child(2){
            span:first-child{
                background: #507EFF;
                border: 1px solid #507EFF;
            }
        }

        &:last-child{
            margin-right: 0;
            span:first-child{
                background: #DEDFE2;
                border: 1px solid #DEDFE2;
            }
        }
    }
}

.showBooth{
    /deep/.el-dialog__body{
        padding: 0 20px;

        .current-row{
            color: #03A9F4;
            font-weight: bold;
        }

        tbody>tr{
            cursor: pointer;
        }
    }

    .show-booth{
        .b-h{
            display: flex;
            margin-bottom: 20px;
            .s-box{
                margin-left: 15px;
                button{
                    margin-left: 15px;
                }
            }
        }
    }
}

.booth-box-view{
    width: 100%;
    min-height: 750px;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 48px 17px;
    overflow: hidden;
    background-image: url("../../assets/img/booth1.jpg");
    .box-view{
        position: absolute;
        width: 100%;
        display: flex;

        &.view-01{
            /*top: 60%;*/
            /*<!--左边距 = 元素宽度 + 内边距（15px）-->*/
            right: 185.72px;
            flex-wrap: wrap;
            bottom: 193.32px;
            width: 618.64px;
            .cell-item{
                &:nth-child(1),
                &:nth-child(12){
                    border-left: 1px solid #333;
                }
            }
        }
        &.view-02{
            /*top: 60%;*/
            /*<!--左边距 = 元素宽度 + 内边距（11px）-->*/
            right: 129.48px;
            flex-wrap: wrap;
            bottom: 411.3px;
            width: 674.88px;

            .cell-item{
                &:nth-child(1),
                &:nth-child(13){
                    border-left: 1px solid #333;
                }
                &:nth-child(12){
                    border-top: 0;
                    border-bottom: 1px solid #333;
                }
            }
        }

        &.view-01,&.view-02{
            .cell-item{
                border-top: 1px solid #333;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11){
                    border-top: 0;
                    border-bottom: 1px solid #333;
                    margin-bottom: 5px;
                }
            }
        }
        &.view-04{
            .cell-item{
                border: 0;
                border-left: 0;
                border-top: 1px solid #333;
                border-bottom: 1px solid #333;
                &:nth-child(1){
                    border-left: 0;
                    margin-bottom: 5px;
                }
            }
        }

        &.view-03{
            left: 129.48px;
            width: 337.44px;
            bottom: 490px;
            .cell-item {
                border-bottom: 1px solid #333;
            }
        }

        &.view-04{
            right: 17px;
            display: flex;
            flex-flow: column;
            width: 56.24px;
            bottom: 193.32px;
        }

        &.view-05{
            left: 129.48px;
            flex-wrap: wrap;
            bottom: 48px;
        }

        .cell-item{
            height: 72.66px;
            width: 56.24px;
            background: #9addf7;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;
            border-right: 1px solid #333;
            cursor: pointer;
            font-weight: 700;
            position: relative;
            &:hover{
                color: #fff;
                background-color: #7eb0ff;
            }
            &:nth-child(1){
                border-left: 1px solid #333;
            }

            &.okChange {
                color: #fff;
                background-color: #507EFF;
            }

            &.stop {
                background-color: #DEDFE2;
            }

            &.uChange{
                color: #fff;
                background-color: #ff9800;
            }

            .show-hover{
                position: absolute;
                width: 267.2px;
                right: 65px;
                z-index: 10;
                height: 320px;
                display: none;
                transition: all .3s;
                .hover-box{
                    height: 290px;
                    background: #fff;
                    border-radius: 4px;
                    position: relative;
                    z-index: 15;
                    border: 1px solid #FFE2D9;

                    &:after{
                        width: 15px;
                        height: 15px;
                        content: "";
                        position: absolute;
                        background: inherit;
                        bottom: -13px;
                        z-index: -1;
                        border: 1px solid #FFE2D9;
                        transform: rotate(45deg) translateX(-50%);
                        left: 50%;
                    }
                    .h-head,.h-body{
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-weight: 100;
                        position: relative;
                        z-index: 30;
                        background: inherit;
                    }

                    .h-head{
                        height: 40px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 15px;
                        border-radius: 4px 4px 0 0;
                        background: #507eff;
                        color: #fff;

                        .name{
                            width: 75%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .h-body{
                        color: #333 !important;
                        padding: 10px 10px;
                        min-height: 195px;
                        max-height: 195px;
                        overflow: auto;
                        /*滚动条样式*/
                        &::-webkit-scrollbar{
                            width: 5px;     /*高宽分别对应横竖滚动条的尺寸*/
                            height: 5px;
                        }
                        &::-moz-scrollbar{
                            width: 5px;     /*高宽分别对应横竖滚动条的尺寸*/
                            height: 5px;
                        }
                        &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
                            border-radius: 10px;
                            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                            background: #DEDEDE;
                        }
                        &::-webkit-scrollbar-thumb:hover{
                            background-color: #C3D3FF;
                        }

                        .job-list{
                            height: 35px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .job-name{
                                width: 72%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .price{
                                font-weight: bold;
                                color: #FF5C2C;
                            }
                        }
                    }

                    .h-footer{
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        button{
                            cursor: pointer;
                            height: 30px;
                            width: 90px;
                            color: #507eff;
                            outline: none;
                            border: 1px solid #507eff;
                            border-radius: 4px;
                            background: #fff;
                            transition: all 0.2s;
                            &:hover{
                                width: 110px;
                                transition: all 0.2s;
                                color: #cb86ed;
                                border-color:#cb86ed;
                            }
                        }
                    }
                }
            }

            &.show{
                .show-hover{
                    display: block;
                    transition: all .3s;
                    animation: show .3s;
                }

                @keyframes show {
                    0%{opacity: 0;transform: scale(0);}
                    100%{opacity: 1;transform: scale(1);}
                }
            }
        }

        &.view-01{
            .cell-item{
                .show-hover{
                    bottom: 60px;
                    right: -106.88px;

                    .hover-box{
                        background: #fff;
                        .h-footer{
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-weight: 100;
                            position: relative;
                            z-index: 30;
                            background: inherit;
                        }
                    }
                }
            }
        }

        &.view-02,&.view-03{
            .cell-item{
                .show-hover{
                    top: 60px;
                    right: -106.88px;
                    padding-top: 20px;
                    box-sizing: border-box;

                    .hover-box{
                        height: 290px;
                        background: #fff;
                        border-radius: 4px;
                        position: relative;
                        z-index: 15;
                        border: 1px solid #FFE2D9;

                        &:after{
                            width: 15px;
                            height: 15px;
                            content: "";
                            position: absolute;
                            background: inherit;
                            top: -3px;
                            z-index: 0;
                            transform: rotate(45deg) translateX(-50%);
                            left: 50%;
                        }

                        .h-footer{
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-weight: 100;
                            position: relative;
                            z-index: 30;
                            background: inherit;
                        }
                    }
                }
            }
        }

        &.view-04{
            .cell-item{
                .show-hover{
                    width: 270px;
                    right: 45px;
                    height: 290px;

                    .hover-box{
                        width: 250px;
                        height: 290px;
                        background: #fff;
                        border-radius: 4px;
                        position: relative;
                        z-index: 15;
                        border: 1px solid #FFE2D9;

                        &:after{
                            width: 15px;
                            height: 15px;
                            content: "";
                            position: absolute;
                            background: inherit;
                            left: 97%;
                            z-index: 0;
                            transform: rotate(45deg);
                            top: 50%;
                        }
                    }
                }
            }
        }

        &.view-05{
            .cell-item{
                .show-hover{
                    bottom: 60px;
                    right: -106.88px;

                    .hover-box{
                        background: #fff;
                        .h-footer{
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-weight: 100;
                            position: relative;
                            z-index: 30;
                            background: inherit;
                        }
                    }
                }
            }
        }
    }
}


@media screen and  (max-width: 1500px){
    /*.booth-box-view{*/
    /*    padding: 37px 12px;*/
    /*    min-height: 580px;*/

    /*    .box-view{*/
    /*        .cell-item{*/
    /*            height: 55px;*/
    /*            width: 41.68px;*/
    /*        }*/

    /*        &.view-01{*/
    /*            left: 470.48px;*/
    /*            bottom: 150px;*/
    /*            width: 469.04px;*/
    /*        }*/

    /*        &.view-02{*/
    /*            left: 470.48px;*/
    /*            flex-wrap: wrap;*/
    /*            bottom: 320px;*/
    /*            width: 500.16px;*/
    /*        }*/

    /*        &.view-03{*/
    /*            left: 97.28px;*/
    /*            width: 255.84px;*/
    /*            bottom: 380px;*/
    /*        }*/

    /*        &.view-04{*/
    /*            right: 12px;*/
    /*            width: 42.64px;*/
    /*            bottom: 150px;*/
    /*        }*/

    /*        &.view-05{*/
    /*            width: 875.28px;*/
    /*            left: 95.36px;*/
    /*            bottom: 37px;*/
    /*        }*/
    /*    }*/
    /*}*/
    .RPd .r-box .r-cell .search .in-item .title{
        width: 23%;
    }
    .RPd .r-box .r-cell .search .in-item .input-v[data-v-787700a0] {
        width: 70%;
    }
    .RPd .r-box .r-cell.job-box .job-cell .job-list{
        width: 32%;
        margin-right: 1.333% !important;
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9),
        &:nth-child(12){
            margin-right: 0;
        }
    }
}

    .u-hide{
        display: none !important;
    }

    .booth-box-view .box-view.linshi{
        top: 118px;
        width: 843.6px;
        .cell-item{
            width: 56.24px;
            height: 54px;
            font-weight: 700px;
            border-top: 1px solid #555 !important;
            border-bottom: 0 !important;

            &:nth-child(13){
                border-left: 0;
            }
        }
    }

</style>
